var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Banner',{attrs:{"banner_img":_vm.resBanners,"banner_en":"THE PARTY BUILDING","banner_cn":"党的建设"}}),_c('div',{staticClass:"contain"},[_c('div',{staticClass:"c-header"},[_c('div',{staticClass:"c-title"},[_vm._v(_vm._s(_vm.infoObj.title))]),_c('div',{staticClass:"c-time"},[_vm._v(" 发布时间： "),_c('span',[_vm._v(_vm._s(_vm.onConversion(_vm.infoObj.time)))])])]),_c('div',{staticClass:"c-main ql-snow ql-editor",domProps:{"innerHTML":_vm._s(_vm.infoObj.content)}}),_c('div',{staticClass:"c-footer clear"},[_c('div',{staticClass:"f-left"},[(_vm.infoObj.prev)?_c('router-link',{attrs:{"to":'/party-details?id=' +
                            _vm.infoObj.prev.id +
                            '&index=' +
                            _vm.infoObj.prev.index +
                            '&type=' +
                            _vm.$route.query.type}},[_vm._v("上一条："+_vm._s(_vm.infoObj.prev.title))]):_vm._e(),(_vm.infoObj.next)?_c('router-link',{attrs:{"to":'/party-details?id=' +
                            _vm.infoObj.next.id +
                            '&index=' +
                            _vm.infoObj.next.index +
                            '&type=' +
                            _vm.$route.query.type}},[_vm._v("下一条："+_vm._s(_vm.infoObj.next.title))]):_vm._e()],1),_c('div',{staticClass:"f-right"},[_c('router-link',{staticClass:"arows",attrs:{"to":'/party?key='+_vm.ptype}},[_vm._v("返回   >>")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }