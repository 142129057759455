<template>
    <div>
        <Banner
            :banner_img="resBanners"
            banner_en="THE PARTY BUILDING"
            banner_cn="党的建设"
        ></Banner>
        <div class="contain">
            <div class="c-header">
                <div class="c-title">{{ infoObj.title }}</div>
                <div class="c-time">
                    发布时间：
                    <span>{{ onConversion(infoObj.time) }}</span>
                </div>
            </div>
            <div
                class="c-main ql-snow ql-editor"
                v-html="infoObj.content"
            ></div>
            <div class="c-footer clear">
                <div class="f-left">
                    <router-link
                        :to="
                            '/party-details?id=' +
                                infoObj.prev.id +
                                '&index=' +
                                infoObj.prev.index +
                                '&type=' +
                                $route.query.type
                        "
                        v-if="infoObj.prev"
                        >上一条：{{ infoObj.prev.title }}</router-link
                    >
                    <router-link
                        :to="
                            '/party-details?id=' +
                                infoObj.next.id +
                                '&index=' +
                                infoObj.next.index +
                                '&type=' +
                                $route.query.type
                        "
                        v-if="infoObj.next"
                        >下一条：{{ infoObj.next.title }}</router-link
                    >
                </div>
                <div class="f-right">
                    <router-link :to="'/party?key='+ptype" class="arows"
                        >返回 &nbsp; >></router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from "@/components/common/Banner.vue";
export default {
    components:{Banner},
    data() {
        return {
            resBanners: [],
            infoObj: {},
            ptype:""
        };
    },
    mounted() {
        this.getBanners();
        // console.log(this.$route.query);
        this.getinfo();
    },
    methods: {
        returnPre() {
            this.$router.go(-1);
        },
        // 时间转换
        onConversion(date) {
            if(!date) return
            // console.log(date);
            var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
            // let nDate = new Date(date),
            var y = nDate.getFullYear(),
                m = ("0" + (nDate.getMonth() + 1)).slice(-2),
                d = ("0" + nDate.getDate()).slice(-2);
            // console.log(date)
            return y + "-" + m + "-" + d;
        },
        // 获取党建新闻
        async getinfo() {
            let resQuery = this.$route.query;
            let res, resDatalist, resNext, resPrev;

            switch (resQuery.type) {
                case "company":
                  this.ptype = "News"
                    res = await this.$request.getNewInfoManageDetail({
                        infoId: resQuery.id
                    });
                    resNext = await this.$request.getPartyNews({
                        pageNo: resQuery.index - 0 + 1,
                        pageSize: 1
                    });
                    resPrev = "";
                    if (resQuery.index - 0 - 1 >= 0) {
                        resPrev = await this.$request.getPartyNews({
                            pageNo: resQuery.index - 0 - 1,
                            pageSize: 1
                        });
                    }
                    resDatalist = {
                        id: res.rows[0].infoManage.infoId,
                        title: res.rows[0].infoManage.infoName,
                        content: res.rows[0].infoManage.infoContent.replace(
                            /src="/g,
                            'src="' + this.$store.state.baseUrl + "/"
                        ),
                        imgSrc: res.rows[0].infoManage.infoImage,
                        index: resQuery.index,
                        type: resQuery.type,
                        time: res.rows[0].infoManage.publishTime,
                        next:
                            resQuery.index - 0 + 1 <=
                            resNext.partyNewsInfo.total
                                ? {
                                      id: resNext.partyNewsInfo.list[0].infoId,
                                      title:
                                          resNext.partyNewsInfo.list[0]
                                              .infoName,
                                      index: resQuery.index - 0 + 1
                                  }
                                : "",
                        prev:
                            resQuery.index - 1 > 0
                                ? {
                                      id: resPrev.partyNewsInfo.list[0].infoId,
                                      title:
                                          resPrev.partyNewsInfo.list[0]
                                              .infoName,
                                      index: resQuery.index - 0 - 1
                                  }
                                : ""
                    };
                    break;
                case "lz":
                    this.ptype = "Column"
                    res = await this.$request.getNewInfoManageDetail({
                        infoId: resQuery.id
                    });
                    resNext = await this.$request.getConstructionType({
                        newsType: 2,
                        pageNo: resQuery.index - 0 + 1,
                        pageSize: 1
                    });
                    resPrev = "";
                    if (resQuery.index - 0 - 1 >= 0) {
                        resPrev = await this.$request.getConstructionType({
                            newsType: 2,
                            pageNo: resQuery.index - 0 - 1,
                            pageSize: 1
                        });
                    }
                    resDatalist = {
                        id: res.rows[0].infoManage.infoId,
                        title: res.rows[0].infoManage.infoName,
                        content: res.rows[0].infoManage.infoContent.replace(
                            /src="/g,
                            'src="' + this.$store.state.baseUrl + "/"
                        ),
                        imgSrc: res.rows[0].infoManage.infoImage,
                        index: resQuery.index,
                        type: resQuery.type,
                        time: res.rows[0].infoManage.publishTime,
                        next:
                            resQuery.index - 0 + 1 <= resNext.newsInfo.total
                                ? {
                                      id: resNext.newsInfo.list[0].infoId,
                                      title: resNext.newsInfo.list[0].infoName,
                                      index: resQuery.index - 0 + 1
                                  }
                                : "",
                        prev:
                            resQuery.index - 1 > 0
                                ? {
                                      id: resPrev.newsInfo.list[0].infoId,
                                      title: resPrev.newsInfo.list[0].infoName,
                                      index: resQuery.index - 0 - 1
                                  }
                                : ""
                    };
                    break;
                case "qt":
                    this.ptype = "Column"
                    res = await this.$request.getNewInfoManageDetail({
                        infoId: resQuery.id
                    });
                    resNext = await this.$request.getConstructionType({
                        newsType: 3,
                        pageNo: resQuery.index - 0 + 1,
                        pageSize: 1
                    });
                    resPrev = "";
                    if (resQuery.index - 0 - 1 >= 0) {
                        resPrev = await this.$request.getConstructionType({
                            newsType: 3,
                            pageNo: resQuery.index - 0 - 1,
                            pageSize: 1
                        });
                    }
                    resDatalist = {
                        id: res.rows[0].infoManage.infoId,
                        title: res.rows[0].infoManage.infoName,
                        content: res.rows[0].infoManage.infoContent.replace(
                            /src="/g,
                            'src="' + this.$store.state.baseUrl + "/"
                        ),
                        imgSrc: res.rows[0].infoManage.infoImage,
                        index: resQuery.index,
                        type: resQuery.type,
                        time: res.rows[0].infoManage.publishTime,
                        next:
                            resQuery.index - 0 + 1 <= resNext.newsInfo.total
                                ? {
                                      id: resNext.newsInfo.list[0].infoId,
                                      title: resNext.newsInfo.list[0].infoName,
                                      index: resQuery.index - 0 + 1
                                  }
                                : "",
                        prev:
                            resQuery.index - 1 > 0
                                ? {
                                      id: resPrev.newsInfo.list[0].infoId,
                                      title: resPrev.newsInfo.list[0].infoName,
                                      index: resQuery.index - 0 - 1
                                  }
                                : ""
                    };
                    break;
                default:
                    //这里是没有找到对应的值处理
                    break;
            }
            // console.log(resPrev);

            this.infoObj = resDatalist;
            // console.log(this.infoObj);
        },
        // 获取banner
        async getBanners() {
            let res = await this.$request.getImageAdvertisingList({
                advertisingPosition: "4"
            });
            if (res.code === 200) {
                this.resBanners = []
                this.resBanners = res.rows;
            }
        }
    },
    watch: {
        // 监听路由发生改变
        $route: {
            handler(newVal) {
                if (newVal.query.id) this.getinfo();
            }
        }
    }
};
</script>
<style lang="scss">
.c-main {
    padding: 10px 40px 20px;

    img {
        max-width: 100% !important;
        display: block;
        margin: 40px auto;
    }

    p {
        color: #333333;
        text-indent: 32px;
        font-size: 18px;
        line-height: 30px;
        padding: 6px 0;
    }

    span {
        color: #333333;
    }

    b {
        display: inline-block;
        font-size: 18px;
        line-height: 30px;
        padding: 10px 0;
    }
}
</style>
<style lang="scss" scoped>
.contain {
    width: 62.5vw;
    min-height: 31.25vw;
    margin: 3.13vw auto;
    padding: 0 0 5.21vw 0;
    background-color: #f7f7f7;
    position: relative;

    .c-header {
        padding: 2.08vw 0 1.04vw 0;
        margin: 0 2.08vw;
        text-align: left;
        border-bottom: 0.05vw solid #cccccc;

        .c-title {
            display: inline-block;
            font-size: 1.25vw;
            color: #333333;
            border-left: 0.52vw solid #f3ca00;
            padding: 0 0 0 0.52vw;
            font-weight: bold;
        }

        .c-time {
            font-size: 0.83vw;
            color: #666666;
            padding: 1.56vw 0 0 0;
            font-weight: bold;
        }
    }

    .c-footer {
        padding: 1.56vw 0;
        margin: 0 2.08vw;
        border-top: 0.05vw solid #cccccc;
        width: 58.33vw;
        position: absolute;
        bottom: 0;
        left: 0;

        .f-left {
            float: left;
            width: 36.46vw;

            a {
                font-size: 0.83vw;
                color: #666666;
                padding: 0.52vw 0;
                width: 36.46vw;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;

                &:hover {
                    color: #f3ca00;
                }
            }
        }

        .f-right {
            float: right;
            width: 6.77vw;

            .arows {
                width: 6.77vw;
                height: 1.56vw;
                line-height: 1.56vw;
                margin-top: 1.35vw;
                background-color: #f3ca00;
                color: #ffffff;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 1920px) {
    .contain {
        width: 1200px;
        min-height: 600px;
        margin: 60px auto;
        padding: 0 0 100px 0;
        background-color: #f7f7f7;
        position: relative;

        .c-header {
            padding: 40px 0 20px 0;
            margin: 0 40px;
            text-align: left;
            border-bottom: 1px solid #cccccc;

            .c-title {
                display: inline-block;
                font-size: 24px;
                color: #333333;
                border-left: 10px solid #f3ca00;
                padding: 0 0 0 10px;
                font-weight: bold;
            }

            .c-time {
                font-size: 16px;
                color: #666666;
                padding: 30px 0 0 0;
                font-weight: bold;
            }
        }

        .c-footer {
            padding: 30px 0;
            margin: 0 40px;
            border-top: 1px solid #cccccc;
            width: 1120px;
            position: absolute;
            bottom: 0;
            left: 0;

            .f-left {
                float: left;
                width: 700px;

                a {
                    font-size: 16px;
                    color: #666666;
                    padding: 10px 0;
                    width: 700px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;

                    &:hover {
                        color: #f3ca00;
                    }
                }
            }

            .f-right {
                float: right;
                width: 130px;

                .arows {
                    width: 130px;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 26px;
                    background-color: #f3ca00;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
